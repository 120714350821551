import { computed, type ComputedRef } from 'vue';
import { CtxOrderMinimalDto, PaginationStep } from '@containex/portal-backend-dto';
import { useOrderConfirmationsStore } from '../stores/order-confirmations';
import { ctxOrdersApi } from '@containex/portal-backend-api-client';
import { httpClient } from '@/common/api/http-client';

export interface OrderConfirmationsQuery {
    orders: ComputedRef<CtxOrderMinimalDto[]>;
    totalOrderAmount: ComputedRef<number>;
}

export interface OrderConfirmationsAction {
    fetchOrders(orderAmount: number, step: PaginationStep): Promise<void>;
}

export function useOrderConfirmationsQuery(): OrderConfirmationsQuery {
    const store = useOrderConfirmationsStore();

    return {
        orders: computed(() => store.orders),
        totalOrderAmount: computed(() => store.totalOrderAmount),
    };
}

export function useOrderConfirmationsAction(): OrderConfirmationsAction {
    const store = useOrderConfirmationsStore();

    return {
        async fetchOrders(orderAmount: number, step: PaginationStep): Promise<void> {
            const orders = store.orders;
            let pageCursor;

            if (step === PaginationStep.New) {
                pageCursor = null;
            } else {
                const filterOrder = step === PaginationStep.Next ? orders[orders.length - 1] : orders[0];
                pageCursor = {
                    startDate: filterOrder?.date.toLocaleString(),
                    startId: filterOrder?.id,
                };
            }

            const response = await ctxOrdersApi.fetchCtxOrders(httpClient, {
                orderAmount: String(orderAmount),
                step,
                pageCursorDate: pageCursor?.startDate,
                pageCursorId: pageCursor?.startId,
            });

            store.orders = response.data.orders;
            store.totalOrderAmount = response.data.totalOrderAmount;
        },
    };
}
