import { defineStore } from 'pinia';
import { CtxOrderMinimalDto } from '@containex/portal-backend-dto';

interface OrderConfirmationsState {
    orders: CtxOrderMinimalDto[];
    totalOrderAmount: number;
}

export const useOrderConfirmationsStore = defineStore('orderConfirmations', {
    state: (): OrderConfirmationsState => ({
        orders: [],
        totalOrderAmount: 0,
    }),
});
