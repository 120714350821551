<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import { useAsyncTask } from 'vue-concurrency';
    import { useI18n } from 'vue-i18n';
    import { useOrderConfirmationsAction, useOrderConfirmationsQuery } from '../composables/order-confirmations';
    import { useDateFormatter } from '@/composables/date-format';
    import { isDefined, isStringEmpty } from '@containex/common-utils';
    import { getLogger } from '@/logger/logger';
    import BlockUI from 'primevue/blockui';
    import { computed, ref } from 'vue';
    import { DEFAULT_ROW_AMOUNT_OPTIONS_TABLE, DEFAULT_ROWS_PER_TABLE } from '@/constants';
    import {
        CtxOrderMinimalDto,
        type FetchPaginatedDataDto,
        PaginationStep,
        ProvisionType,
    } from '@containex/portal-backend-dto';
    import Paginator, { type PageState } from 'primevue/paginator';
    import { marketContainsProvision } from '@/util/marketContainsProvision';
    import { useMarketQuery } from '@/composables/market';
    import ReceiptListItem, { type ReceiptHeaderItem } from '../components/ReceiptListItem.vue';

    const { t } = useI18n();
    const { orders, totalOrderAmount } = useOrderConfirmationsQuery();
    const orderAction = useOrderConfirmationsAction();
    const { dateFormatter } = useDateFormatter();
    const logger = getLogger('OrderConfirmationsListView');
    const { market } = useMarketQuery();

    const isLoading = ref(false);
    const paginatorCurrentRows = ref(DEFAULT_ROWS_PER_TABLE);
    const paginatorCurrentPage = ref(0);
    const paginatorPageLinkSize = ref(2);

    const task = useAsyncTask(async () => {
        await orderAction.fetchOrders(paginatorCurrentRows.value, PaginationStep.New);
    }).perform();

    const fetchOrderConfirmationsTask = useAsyncTask(async (signal, data: FetchPaginatedDataDto) => {
        logger.debug('OrderConfirmationsListView', 'fetching order confirmations for table');
        isLoading.value = true;
        await orderAction.fetchOrders(data.dataAmount, data.step);
        isLoading.value = false;
    }).drop();

    const hasMarketRentalProvisionType = computed(() => {
        if (market.value != null) {
            return marketContainsProvision(market.value, ProvisionType.Rental);
        }
        return false;
    });

    function getHeaderItems(order: CtxOrderMinimalDto): ReceiptHeaderItem[] {
        // TODO: CPP-1210: add company_name by using order.invoice_address.company_name
        return [
            {
                title: t('ACCOUNT.ORDER_CONFIRMATIONS.ID'),
                value: order.id,
            },
            hasMarketRentalProvisionType.value
                ? {
                      title: t('ACCOUNT.ORDER_CONFIRMATIONS.TYPE'),
                      value: order.type === ProvisionType.Rental ? t('COMMON.RENTAL') : t('COMMON.SALES'),
                  }
                : undefined,
            {
                title: t('ACCOUNT.ORDER_CONFIRMATIONS.DATE'),
                value: dateFormatter.formatDateTwoDigits(new Date(order.date)),
            },
            {
                title: t('ACCOUNT.ORDER_CONFIRMATIONS.REFERENCE'),
                value: order.reference == null || isStringEmpty(order.reference) ? '-' : order.reference,
            },
        ].filter((item) => isDefined(item));
    }

    async function handlePaginatorChange(event: PageState): Promise<void> {
        if (paginatorCurrentPage.value === event.page && paginatorCurrentRows.value === event.rows) {
            return;
        }

        if (paginatorCurrentRows.value !== event.rows) {
            paginatorCurrentRows.value = event.rows;
            await fetchOrderConfirmationsTask.perform({
                dataAmount: event.rows,
                step: PaginationStep.New,
            });
            event.page = 0;
        } else {
            await fetchOrderConfirmationsTask.perform({
                dataAmount: event.rows,
                step: paginatorCurrentPage.value < event.page ? PaginationStep.Next : PaginationStep.Previous,
            });
        }

        paginatorCurrentPage.value = event.page;
        getPageLinkSize();
    }

    function getPageLinkSize(): void {
        const lastPage = Math.ceil(totalOrderAmount.value / paginatorCurrentRows.value) - 1;
        if (paginatorCurrentPage.value === 0 || paginatorCurrentPage.value === lastPage) {
            paginatorPageLinkSize.value = 2;
        } else {
            paginatorPageLinkSize.value = 3;
        }
    }
</script>

<template>
    <div class="order-list-page-container">
        <h2 class="text-2xl-bold-line-height-auto">{{ t('ACCOUNT.ORDER_CONFIRMATIONS.TITLE') }}</h2>
        <div v-if="task.isSuccessful" class="order-list-container">
            <BlockUI :blocked="fetchOrderConfirmationsTask.isRunning" class="full-width">
                <template v-if="orders.length > 0">
                    <div v-for="order of orders" :key="order.id" class="order-container">
                        <ReceiptListItem :header-items="getHeaderItems(order)" :items="order.items" />
                    </div>
                </template>
                <div v-else>
                    <p>{{ t('ORDER_LIST.NO_ORDERS') }}</p>
                </div>
                <Paginator
                    v-if="orders.length > 0 && !fetchOrderConfirmationsTask.isRunning"
                    :rows="paginatorCurrentRows"
                    :total-records="totalOrderAmount"
                    :rows-per-page-options="DEFAULT_ROW_AMOUNT_OPTIONS_TABLE"
                    :first="paginatorCurrentPage * paginatorCurrentRows"
                    :page-link-size="paginatorPageLinkSize"
                    :current-page-report-template="
                        t('ACCOUNT.PAGINATOR', {
                            first: '{first}',
                            last: '{last}',
                            totalRecords: '{totalRecords}',
                        })
                    "
                    template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                    @page="handlePaginatorChange"
                ></Paginator>
                <p v-else>
                    {{ t('ACCOUNT.ORDER_CONFIRMATIONS.NO_ORDERS') }}
                </p>
            </BlockUI>
        </div>
        <LoadingSpinner v-else-if="task.isRunning" />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .order-list-page-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: main.$spacing-6;
    }

    .order-list-container {
        display: flex;
        flex-direction: row;
        gap: main.$spacing-5;
    }

    .order-container {
        border: 1px solid main.$color-background-lightgray;
        border-radius: 6px;
        margin-bottom: main.$spacing-6;
    }
</style>
